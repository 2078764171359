body, html {
  font-family: 'JetBrains Mono', monospace;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0; /* Add this line */
}



@media only screen and (max-width: 600px) {
  body, html {
    font-family: 'JetBrains Mono', monospace;
    height: 100%;
    width: 100%;
    margin: 0;
  }
  }

  .left-content a {
    color: inherit;           /* Inherit text color from parent */
    text-decoration: none;    /* Remove underline */
    outline: none;    
    display: flex;        /* Remove focus outline (not recommended for accessibility) */
  }

.answer-form {
width: 400px;
margin: 0 auto;
  /* Add styling for the outer div here */
}

@media only screen and (max-width: 600px) {
  .answer-form {
    width: 80%;
    margin: 0 auto;
      /* Add styling for the outer div here */
    }
  }


.answer-form input {
  font-family: 'JetBrains Mono', monospace;
  font-size: 16px;
  color: white;
  padding: 16px;
  background-color: #3F4651;
  border: 4px solid #E15980;
  border-radius: 10px;
}

.answer-form input:focus {
  font-family: 'JetBrains Mono', monospace;
  font-size: 16px;
  color: white;
  padding: 16px;
  outline: none;
  box-shadow: 0 0 0 4px #305097;
  background-color: #3F4651;
  border: 4px solid #E15980;
  border-radius: 10px;

}

.answer-form input::placeholder {
  color: #959EAC;
}

.answer-form input.white-input {
  padding: 16px;
  background-color: #3F4651;
  border: 4px solid #E15980;
  border-radius: 10px;
}

.answer-form.incorrect-answer input {
  padding: 16px;
  background-color: #513F47;
  border: 4px solid #E15980;
  border-radius: 10px;
}

.app {
  background-color: #343A44;
  min-height: 100vh; /* Set minimum height to 100% of the viewport height */
}

.app.dark {
  background-color: #718394; /* Dark theme color */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #343A44;
  padding: 20px 40px; /* Add padding for spacing */
}

@media only screen and (max-width: 600px) {
  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #343A44;
    padding: 20px 20px; /* Add padding for spacing */
  }
  }

h2 {
  margin: 0;
  color: white;
}

h1 {
  margin: 0;
  color: white;
}

.difficulty-selector {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  align-items: center; /* Center the children vertically */
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .difficulty-selector {
    display: flex;
    align-items: center; /* Center the children vertically */
  }
  }

.difficulty-option {
  cursor: pointer;
  margin-right: 10px;
  color: #959EAC; /* Grey text for non-selected difficulties */
  text-decoration: none;
}

/* Style for non-selected difficulty button */
.difficulty-selector button {
  font-family: 'JetBrains Mono', monospace;
  font-size: 16px;
  background-color: #343A44;
  color: #959EAC;
  border: none;
  margin: 0 5px;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
}

/* Style for selected difficulty button */
.difficulty-selector button.selected {
  font-size: 16px;
  font-weight: 600;
  background-color: #343A44;
  border-bottom: 2px solid #E15980;
  color: white;
  /* Add any additional styles for the selected button */
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column; /* Display the elements vertically */
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .input-container {
    flex-direction: column-reverse; /* Reverse the order for smaller screens */
  }
  }


#answer-text {
  font-family: 'JetBrains Mono', monospace;
  margin-left: -19px;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  #answer-text {
    font-family: 'JetBrains Mono', monospace;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
  }
  }

#work-area {
  font-family: 'JetBrains Mono', monospace;
  resize: none;
  color: #ffffff;
  margin-bottom: 16px;
  font-size: 16px;
  height: 200px;
  width: 400px;
  background-color: #3F4651;
  border: 4px solid #2F343C;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  padding: 16px;
}

@media only screen and (max-width: 600px) {
  #work-area {
    font-family: 'JetBrains Mono', monospace;
    resize: none;
    color: white;
    margin-bottom: 16px;
    font-size: 16px;
    height: 200px;
    width: 80%;
    background-color: #3F4651;
    border: 4px solid #2F343C;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    padding: 16px;
  }
  }

#work-area:focus {
  font-family: 'JetBrains Mono', monospace;
  resize: none;
  color: #ffffff;
  margin-bottom: 16px;
  font-size: 16px;
  height: 200px;
  outline: none;
  box-shadow: 0 0 0 4px #305097;
  width: 400px;
  background-color: #3F4651;
  border: 4px solid #2F343C;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  padding: 16px;
}

#work-area::placeholder,
#work-area::-ms-input-placeholder {
  color: rgb(255, 255, 255) !important; /* Change this to the color you desire */
}

.problem {
  text-align: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 30px;
  font-size: 32px; /* Set font size */
  color: rgb(255, 255, 255); /* Set font color */
}

.controls {
  margin-bottom: 30px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center; /* Center the div horizontally */
  align-items: center; /* Center the div vertically */
}

.controls h3 {
  font-family: 'JetBrains Mono', monospace;
  margin: 0;
  margin-right: 16px;
  margin-left: 16px;
}

.prev-btn {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #343A44;
  border: 0px;
  color: #959EAC;
  border-radius: 10px;
}

.prev-btn:hover {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #3F4651;
  cursor: pointer;
  border: 0px;
  color: #959EAC;
  border-radius: 10px;
}

.next-btn {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #343A44;
  border: 0px;
  color: #959EAC;
  border-radius: 10px;
}

.next-btn:hover {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #3F4651;
  cursor: pointer;
  border: 0px;
  color: #959EAC;
  border-radius: 10px;
}

.btn-2 {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #343A44;
  border: 0px;
  color: #959EAC;
  border-radius: 10px;
}

.btn-2:hover {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #3F4651;
  cursor: pointer;
  border: 0px;
  color: #959EAC;
  border-radius: 10px;
}

.btn-1-full {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  width: 100%;
  font-size: 16px;
  background-color: #2F343C;
  border: 0px;
  color: #ffffff;
  border-radius: 10px;
}

.btn-1-full:hover {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  width: 100%;
  font-size: 16px;
  background-color: #2C3139;
  border: 0px;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.btn-1 {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #2F343C;
  border: 0px;
  color: #ffffff;
  border-radius: 10px;
}

.btn-1:hover {
  font-family: 'JetBrains Mono', monospace;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #2C3139;
  border: 0px;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.btn-prim {
  font-family: 'JetBrains Mono', monospace;
  width: 100%;
  margin-bottom: 12px;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #ffffff;
  border: 0px;
  color: #2C3139;
  border-radius: 10px;
}

.btn-prim:hover {
  font-family: 'JetBrains Mono', monospace;
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #ececec;
  border: 0px;
  color: #2C3139;
  border-radius: 10px;
  cursor: pointer;
}

#g-logo {
  width: 19px;
  margin-bottom: -3px;
  margin-right: 12px;
}

#d-logo {
  width: 22px;
  margin-bottom: -3px;
  margin-right: 12px;
}

.icon {
  margin-bottom: -2px;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #2F343C;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

}

.green-line {
  height: 10px;
  background-color: #33CB82;
  width: 100%;
  animation: shrink 3s ease-in-out forwards;
}

@keyframes shrink {
  to {
    width: 0;
  }
}

#logo {
  width: 20px;
  height: 20px;
  border-radius: 8px;
  border: 2px solid #E15980;
  margin-right: 10px;
  margin-top: 4px;

}

.left-content {
  display: flex;
  align-items: center;
  align-items: center; /* Add this line to vertically align the items */
}

.key {
  margin-top: 32px;
  width: 100%;
  color: #959EAC;
}

@media only screen and (max-width: 600px) {
  .key {
    margin-top: 32px;
    width: 90%;
    margin-left: 20px;
    padding-bottom: 46px;

    text-align: center;
    color: #959EAC;
  }
  }

#enter {
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #3F4651;
}

.sign-in-message {
  text-align: center;
  margin: auto;
  margin-top: 80px;
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .sign-in-message {
    text-align: center;
    margin: auto;
    margin-top: 80px;
    width: 90%;
    margin-right: 15px;
    margin-left: 15px;
  }
  }

.sign-in-message h1 {

  margin-bottom: 30px;

}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #636973;
}

.content-body {
  margin-top: 50px;
  justify-content: center; /* Center the children horizontally */
  align-items: center; /* Center the children vertically */

}

@media only screen and (max-width: 600px) {
  .content-body {
    margin-top: 24px;

  }
  }

  .center-content {
    margin-top: -60px;
    width: 100%;
    display: flex;
    position: row;
    justify-content: center; /* Center the children horizontally */
    align-items: center; /* Center the children vertically */
  }

  @media only screen and (max-width: 600px) {
    .center-content {
      margin-top: -0px;
      width: 100%;
      display: flex;
      justify-content: center; /* Center the children horizontally */
      align-items: center; /* Center the children vertically */
    }
    }

    .right-content {
      display: flex;
      height: auto;
      justify-content: center; /* Center the children horizontally */
      align-items: center; /* Center the children vertically */
    }
    
    .right-content p {
      padding: 0px;
      margin: 0px;
    }

    .counter {
      margin-right: 24px;
      color: #ffffff;
      display: flex;
      font-size: 16px;
      padding: 8px 2px;
    }

    #brain {
      color: #959EAC;
      width: 22px;
      height: auto;
      margin-right: 8px;
    }