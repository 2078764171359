body {
  margin: 0;
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'JetBrains Mono', monospace;
}

#icon {
  margin: 0;
}

.FaArrowRight {
  color: white;
}

